import React, { useContext, useState } from "react";

import SimpleInput from "../input/SimpleInput";
import Button from "../button/Button";
import FORMS from "../constants/forms";
import { LoginValues } from "./types";
import { getParameterByName } from "../../utils/helpers";
import styles from "./authForm.module.css";
import { setNewPassword } from "../../modules/@supabase/auth";

const NewPasswordForm = () => {
  const [error, setError] = useState(null);
  const [buttonText, setButtonText] = useState("Reset password");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleNewPassword = async ({ password }: LoginValues) => {
    const token = getParameterByName("access_token");
    await setNewPassword({ token, password });
    if (error) {
      setError(error);
    }
    setButtonText("Password reset successfully");
    setButtonDisabled(true);
  };

  const SubmitButton = (): JSX.Element => {
    return (
      <div className={styles.button}>
        <Button type="submit" text={buttonText} disabled={buttonDisabled} />
      </div>
    );
  };

  const formData = {
    ...FORMS.NEWPASSWORD,
    handleSubmit: handleNewPassword,
    submitButton: SubmitButton,
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.modalTitle}>Set a new password</div>
        <div className={styles.modalBody}>Enter your new password below.</div>
        <SimpleInput data={formData} />
        {error && <div className={styles.error}>{error}</div>}
      </div>
    </>
  );
};

export default NewPasswordForm;
